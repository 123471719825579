const NotFound = () => {
  return (
    <div className="not-fount">
      <h2>Sorry!</h2>
      <p>the page you're looking for is not here!</p>
    </div>
  );
};

export default NotFound;
